.contacts-head {
	position: relative;
	height: 100vh;
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: rgba(#000, 0.7);
		}
	}
	&__image {
		width: 100%;
		height: 100%;
	}
	&__wrap {
		position: relative;
		z-index: 2;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: #fff;
		padding-bottom: 60px;
	}
	&__title {
    margin-bottom: 0;
    font-size: 22px;
    font-family: "playfair-regular";
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    color: #fff;
    text-shadow: 1px 1px 0px #251f1f;
	}
	&__row {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
	}
	&__name {
		font-size: 30px;
    font-family: "playfair-regular";
	}
	&__address {
		font-size: 20px;
    font-family: "playfair-regular";
		line-height: 30px;
	}
	&__col {
		font-size: 20px;
    font-family: "playfair-regular";
		line-height: 30px;
	}
	&__phone {}
	&__link {
		color: #fff
	}
}
.map {
	width: 100%;
	height: 550px;
}
#map {
	width: 100%;
	height: 100%;
}