.services {
	margin: 70px 0;
	
	@media (min-width: 480px) {
		margin: 100px 0;
	}
}

.services-head__subtitle {
	letter-spacing: 6px;
	text-transform: uppercase;
	font-size: 12px;
	color: #fff;
}
.services__list {
	display: flex;
	height: 90vh;
	position: relative;
}

.services__num {
	position: relative;
	width: 100%;
	text-align: center;
	font-size: 50px;
	font-family: "playfair-regular";
	margin-bottom: 10px;
	margin-top: 10vh;
	color: #fff;
	opacity: 0.4;
	z-index: 1;
}
.services-item {
	display: block;
	position: absolute;
	top: 0;
	width: 20%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	transition: transform 0.25s linear;
	&::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: -1px;
    right: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
//		background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(37, 31, 31) 90%, rgb(37, 31, 31) 0%);
	}
	&:nth-child(1) {
		left: 0;
		background-image: url(../img/services_01.jpg);
	}
	&:nth-child(2) {
		left: 20%;
		background-image: url(../img/services_02.jpg);
		
	}
	&:nth-child(3) {
		left: 40%;
		background-image: url(../img/services_03.jpg);
	}
	&:nth-child(4) {
		left: 60%;
		background-image: url(../img/services_04.jpg);
	}
	&:nth-child(5) {
		left: 80%;
		background-image: url(../img/services_05.jpg);
	}
	&:hover {
		transform: scale(1.03);
		z-index: 10;
		box-shadow: 0 1px 10px 5px #d5a172;
		.services-item__text {
			color: #fff;
		}
		.services-item__btn {
//			display: block;
		}
	}
}

.services-item__content {
	position: relative;
	width: 900px;
	padding-top: 120px;
	opacity: 0;
	color: #fff;
	z-index: 2;
	transition: all 0s linear;
}
.services-item__title {
	padding-left: 300px;
	font-size: 24px;
	margin-bottom: 30px;
}
.services-item__text {
	display: block;
	font-family: "muller-ultra-light", Arial, sans-serif;
	width: 200px;
	text-align: center;
	margin: 0 auto;
	padding: 17px 0 15px;
	line-height: 14px;
	font-size: 12px;
	letter-spacing: 1px;
	color: transparent;
	text-transform: uppercase;
	text-shadow: none;
}

.services__triggers {
	position: absolute;
	z-index: 20;
	width: 100%;
	left: 0;
	bottom: 60px;
	display: flex;
//	align-items: flex-start;
}
.services-trigger {
	position: absolute;
	z-index: 2;
	font-family: "playfair-regular";
	width: 100%;
	min-height: 35vh;
	left: 0;
	bottom: 60px;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	padding: 5px 15px;
	text-align: center;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-shadow: 1px 1px 0px #251f1f;
}

.services-item__btn {
	font-family: "muller-ultra-light", Arial, sans-serif;
	display: none;
	width: 200px;
	padding-left: 0;
	padding-right: 0;
	text-align: center;
	margin: 0 auto;
}