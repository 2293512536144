.section-head {
	margin-bottom: 40px;
	text-align: center;
	&__title {
		color: #fff;
		font-size: 18px;
		margin-bottom: 20px;
		font-family: "playfair-regular";
		
		@media (min-width: 480px) {
			font-size: 22px;
		}
	}
	&__text {
		color: #fff;
		width: 100%;
		margin: 0 auto 20px;
		font-size: 14px;
		line-height: 20px;
		
		@media (min-width: 480px) {
			width: 400px;
			font-size: 16px;
		}
	}
}
