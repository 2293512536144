@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/swiper";

html {
  font-size: $base-font-size;
  height: 100%;
	font-family: "muller-ultra-light", Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
}

body {
	height: 100%;
	overflow-x: hidden;
	background-color: #251f1f;
	&.no-scroll {
		height: 100vh;
		overflow: hidden;
	}
	&.menu-open {
		.page-lines {
			display: none;
		}
		.main-nav {
			transform: translate(0, 0);
		}
		.container-perspective {
			
			position: fixed;
			&__inner {
				transform-origin: 50% 50%;
				position: relative;
				z-index: 10;
				height: 100%;
				min-height: 100%;
				overflow: hidden;
				transform: translateZ(-1000px) translateX(-34%) rotateY(45deg);
			}
		}
		
		
	}
}
.container-bg {
	width: 1200px;
	margin: 0 auto;
	background-color: #332b2b;
}
.container-perspective {
	background-color: #251f1f;
	position: relative;
	width: 100%;
	height: 100%;
	perspective: 1500px;
	&__inner {
//		position: absolute;
    overflow: hidden;
    width: 100%;
//    height: 100%;
    backface-visibility: hidden;
    transition: transform .5s cubic-bezier(.215,.61,.355,1);
    transition: transform .5s cubic-bezier(.215,.61,.355,1),-webkit-transform .5s cubic-bezier(.215,.61,.355,1);
    transform-origin: 50% 50%;
    box-shadow: 0 1px 20px 10px #d5a172;
    z-index: 10;
    min-height: 100%;
    overflow: auto;
	}
}
			
h1, h2, h3, h4, h5, h6 { margin: 0; }

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a { text-decoration: none; }

p { margin: 0; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea { vertical-align: middle; }

textarea { resize: none; }

section { position: relative; }

input,
select,
button { outline: none; }

*, *:before, *:after { box-sizing: border-box; }

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content { flex: 1 0 auto; }

.container {
  margin: 0 auto;
	padding: 0 15px;
  max-width: $content-size;
  width: 100%;
	
	@media (min-width: 480px) {
		padding: 0;
	}
}

//Модули
@import "modules/modules";

@import "blocks/blocks"