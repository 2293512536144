.main-nav {
	position: fixed;
	display: flex;
	flex-direction: column;
	right: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	transform: translate(100%, 0);
	z-index: 1;
	transition: translate 0.3s linear;
}
.main-nav__wrapper {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 60%;
	width: 20%;
}
.main-nav__container {
	margin: 0 auto;
}
.main-menu {
	
}
.main-menu__item {
	margin: 15px 0;
}
.main-menu__link {
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-family: "playfair-regular";
	color: #fff;
	opacity: 0.9;
	transition: color 0.2s linear;
	&.active,
	&:hover {
		color: #d5a172;
	}
}
.menu-address {
	color: #fff;
	margin-bottom: 15px;
	line-height: 24px;
}
.menu-social {
	display: flex;
	margin-top: 30px;
	&__link {
		display: block;
		width: 20px;
		height: 20px;
		&:not(:last-child) {
			margin-right: 10px;
		}
		svg {
			display: block;
			width: 100%;
			height: 100%;
			fill: #fff;
			transition: 0.2s linear;
		}
		&:hover {
			svg {
				fill: #d5a172;
			}
		}
	}
}
.menu-phone {
	margin-bottom: 30px;
	color: #fff;
	transition: 0.2s linear;
	cursor: pointer;
	&:hover {
		color: #d5a172;
	}
}
.main-nav .main-screen__btn {

}
.menu-copyright {
	color: #fff;
	flex-shrink: 0;
	padding: 20px 100px;
	text-align: center;
	border-top: 1px solid #fff;
}