.scroll {
	position: relative;
//	&:hover {
//		.scroll__arrow {
//			bottom: 28px;
//		}
//	}
}

.scroll__bg {
	position: relative;
	width: 51px;
	height: 100px;
//	border: 1px solid #d5a172;
	box-shadow: 0 1px 10px 0px #d5a172;
}

.scroll__arrow {
	position: absolute;
	display: block;
	height: 50px;
	width: 1px;
	bottom: 32px;
	left: 25px;
	background-color: #fff;
	box-shadow: 0px 0px 5px 1px #d5a172;
	animation: scrollArrow 1.5s infinite;
	&::before {
		content: "";
		position: absolute;
		display: block;
		height: 1px;
		width: 8px;
		bottom: 3px;
		left: 0;
		background-color: #fff;
		transform: rotate(-40deg);
//		transition: all 0.25s linear;
	}
	&::after {
		content: "";
		position: absolute;
		display: block;
		height: 1px;
		width: 8px;
		bottom: 3px;
		right: 0;
		background-color: #fff;
		transform: rotate(40deg);
//		transition: all 0.25s linear;
	}
}

@keyframes scrollArrow {
	0% {
		bottom: 38px;
	}
	80% {
		bottom: 28px;
	}
	100% {
		bottom: 28px;
	}
}

.scroll__text {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 6px;
	color: #fff;
	margin-left: 3px;
	text-shadow: 1px 1px 0px #251f1f;
}

