.facts {
	margin-bottom: 200px;
}
.facts__wrapper {
	position: relative;
	margin: 50px 0;
}
.facts__info {
	margin-left: 450px;
	padding-top: 10px;
}
.facts__title {
	font-size: 50px;
	text-transform: uppercase;
	margin-bottom: 5px;
	font-weight: 800;
}
.facts__subtitle {
	font-size: 18px;
}
.facts__item {
	margin-top: 120px;
}
.facts__value {
	font-size: 50px;
	font-weight: 800;
	span {
		font-size: 36px;
		font-weight: 400;
	}
}
.facts__desc {
	font-size: 18px;
	span {
		font-weight: 800;
	}
}
.facts__img {
	display: block;
	position: absolute;
	&--left {
		width: 390px;
		height: 230px;
		top: 0;
		left: 0;
		background-color: gray;
	}
	&--right {
		width: 260px;
		height: 160px;
		bottom: 15px;
		right: 0;
		background-color: gray;
	}
}