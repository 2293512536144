.services-page {
//	padding-top: 120px;
}
.services-page__wrapper {
	padding-top: 120px;
	
	@media (min-width: 480px) {
		padding-top: 5vh;
	}
}
.services-page-item {
	margin-bottom: 70px;
	
	
	@media (min-width: 480px) {
		min-height: 90vh;
		margin-bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&:nth-child(2n) {
		.services-page-item__info {
			left: auto;
			right: 0;
		}
	}
}
.services-page-item__wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
	
	@media (min-width: 480px) {
		width: 940px;
		display: block;
	}
	&:hover {
		.services-page-item__img::before {
			opacity: 0.1;
		}
	}
}
.services-page-item__bg {
	position: relative;
	width: 100%;
	height: 240px;
	margin: 0 auto;
	order: 2;
	
	@media (min-width: 480px) {
		width: 740px;
		height: 480px;
	}
	&::before {
		position: absolute;
		content: "";
		display: block;
		width: 10px;
		height: 100%;
		top: 0;
		left: 140px;
		background-color: #251f1f;
		z-index: 2;
		
		@media (min-width: 480px) {
			width: 20px;
			height: 100%;
			top: 0;
			left: 360px;
		}
	}
	&::after {
		position: absolute;
		content: "";
		display: block;
		width: 100%;
		height: 10px;
		top: 115px;
		left: 0;
		background-color: #251f1f;
		z-index: 2;
		
		@media (min-width: 480px) {
			height: 20px;
			top: 230px;
		}
	}
}
.services-page-item__img {
	position: relative;
	width: 100%;
	height: 100%;
	&::before {
		position: absolute;
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: #fff;
		opacity: 0;
		transition: opacity 0.2s linear;
		z-index: 1;
	}
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.services-page-item__info {
	background-color: #251f1f;
	order: 1;
	z-index: 5;
	
	@media (min-width: 480px) {
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 50%;
		padding: 0 30px;
	}
}
.services-page-item__num {
	color: #fff;
	font-family: "playfair-regular";
	font-size: 30px;
	margin-bottom: 10px;
	
	@media (min-width: 480px) {
		font-size: 50px;
		margin-bottom: 20px;
	}
}
.services-page-item__title {
	font-family: "playfair-regular";
	font-size: 18px;
	color: #fff;
	margin-bottom: 15px;
	
	@media (min-width: 480px) {
		font-size: 24px;
		margin-bottom: 20px;
	}
}
.services-page-item__subtitle {
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 15px;
	
	@media (min-width: 480px) {
		font-size: 16px;
		line-height: 1.15;
		margin-bottom: 0;
	}
}