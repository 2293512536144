.page-head {
	padding-top: 150px;
//	background-image: linear-gradient(to bottom, #332b2b 500px, #251f1f 500px);
	margin-bottom: 100px;
}
.page-head__title {
	font-family: "playfair-regular";
	font-size: 30px;
	color: #fff;
	text-align: center;
	margin-bottom: 30px;
}
.page-head__img {
	position: relative;
	display: block;
	margin: 0 30px;
	width: calc(100% - 60px);
	height: 600px;
	object-fit: cover;
	z-index: 2;
}
.news-info {
	color: #fff;
	text-align: center;
	background-color: #332b2b;
	margin-top: -150px;
	padding-top: 200px;
	padding-bottom: 60px;
}
.news-info-article {
	color: #fff;
	text-align: center;
	margin-top: 50px;
	padding-bottom: 60px;
	&::after {
		content: "";
		display: block;
		width: 200px;
		height: 1px;
		background-color: #d5a172;
		margin: 0 auto;
	}
}
.news-info__date {
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 6px;
	margin-bottom: 25px;
}
.news-info__title {
	font-family: "playfair-regular";
	font-size: 24px;
	width: 400px;
	margin: 0 auto 20px;
}
.news-info__text {
	width: 400px;
	margin: 0 auto 40px;
	font-size: 16px;
	line-height: 20px;
}
.news-info__btn {
	margin-right: auto;
	padding: 16px 55px 14px;
	line-height: 12px;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1px;
	color: #fff;
	text-transform: uppercase;
	box-shadow: 0 1px 10px 0px #d5a172;
	transition: all 0.25s linear;
	&:hover,
	&:focus {
		box-shadow: 0 1px 10px 0px #d5a172;
		color: #d5a172;
	}
}
.article {
	width: 60%;
	margin: 0 auto 50px;
}
.article__text {
	color: #fff;
	margin-bottom: 20px;
	text-align: center;
	line-height: 24px;
}