.news {
	margin: 100px 0 50px;
	&__title {
		color: #fff;
		font-size: 22px;
		margin-bottom: 30px;
		font-family: "playfair-regular";
	}
	&__list {
		display: flex;
	}
}

.news-item {
	width: 220px;
	margin-right: 60px;
	margin-bottom: 50px;
	color: #fff;
	&__image {
		display: block;
		width: 100%;
		height: auto;
	}
	&__head {
		margin-top: 25px;
		display: flex;
		align-items: center;
	}
	&__tag {
		font-size: 14px;
		margin-right: 15px;
		color: #d5a172;
	}
	&__date {
		font-size: 14px;
	}
	&__content {
		padding: 15px 0;
	}
}
