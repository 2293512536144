.main-about {
	margin-bottom: 100px;
}
.main-about__wrapper {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	
	@media (min-width: 480px) {
		flex-direction: row;
	}
}
.main-about__info {
	width: 100%;
	order: 2;
	
	@media (min-width: 480px) {
		width: 45%;
	}
}
.main-about__title {
	color: #fff;
	font-size: 18px;
	margin-bottom: 20px;
	font-family: "playfair-regular";
	
	@media (min-width: 480px) {
		font-size: 22px;
	}
}
.main-about__text {
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	margin: 15px 0;
	
	@media (min-width: 480px) {
		font-size: 16px;
		line-height: 24px;
	}
}
.main-about__right {
	width: 100%;
	margin-bottom: 20px;
	order: 1;
	
	@media (min-width: 480px) {
		margin-bottom: 0;
		width: 50%;
	}
}
.main-about__img {
	display: block;
	max-width: 100%;
	height: auto;
}

.main-about__btn {
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	padding: 14px 35px 12px;
	line-height: 10px;
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 1px;
	color: #fff;
	text-transform: uppercase;
	margin: 30px auto 0;
	width: 240px;
	cursor: pointer;
	text-align: center;
	border: none;
	background-color: transparent;
	box-shadow: 0 1px 10px 0px #d5a172;
	transition: all 0.25s linear;
	
	@media (min-width: 480px) {
		padding: 16px 55px 14px;
		line-height: 12px;
		font-size: 12px;
		margin: 30px 0 0;
	}
	&:hover,
	&:focus {
		box-shadow: 0 1px 10px 0px #d5a172;
		color: #d5a172;
	}
}