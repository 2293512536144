.logo {
	display: block;
	width: 200px;
	height: auto;
	svg {
		fill: #fff;
		transition: 0.1s linear;
	}
	.logo__img--mini {
		display: none;
	}
	&.mini {
		width: 90px;
		.logo__img--big {
			display: none;
		}
		.logo__img--mini {
			display: block;
		}
	}
	
}
