.main-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
}
.main-header__wrapper {
	padding: 25px 15px 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	
	@media (min-width: 480px) {
		padding: 50px 0 0;
	}
}
.main-header__col {
	@media (min-width: 480px) {
		width: 20%;
		display: flex;
		justify-content: center;
	}
}
.main-header__logo {
	@media (min-width: 480px) {
		margin-left: calc((100% - 1200px) / 2);
	}
}
.main-header__menu {
	display: flex;
	align-items: center;
}
.main-header__menu-text {
	display: none;
	
	@media (min-width: 480px) {
		margin-right: 15px;
		margin-top: 4px;
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 6px;
		color: #fff;
	}
}
.main-header__menu {
	&.open {
		.main-header__menu-text--open {
			display: none;
		}
		.main-header__menu-text--close {
			display: block;
		}
		.main-header__menu-btn {
			span {
				top: 15px;
				width: 42px;
				transform: rotate(-45deg);
				transition: all 0.2s linear;
				&::after {

				}
				&::before {
					display: none;
				}
				&::after {
					width: 42px;
					top: -1px;
					transform: rotate(90deg);
					transition: all 0.2s linear;
				}
			}
		}
	}
}
.main-header__menu-text--close {
	display: none;
}
.main-header__menu-text--open,
.main-header__menu-text--close {
	text-shadow: 1px 1px 0px #251f1f;
}
.main-header__menu-btn {
	position: relative;
	width: 40px;
	height: 29px;
	cursor: pointer;
	text-shadow: 1px 1px 0px #251f1f;
	span {
		position: absolute;
		display: block;
		width: 40px;
		height: 1px;
		top: 3px;
		background-color: #fff;
		box-shadow: 0px 0px 4px 1px rgba(#d5a172, .0);
		transition: all 0.2s linear;
		&::before,
		&::after {
			content: "";
			display: block;
			width: 30px;
			height: 1px;
			background-color: #fff;
			box-shadow: 0px 0px 4px 1px rgba(#d5a172, .0);
			transition: all 0.2s linear;
		}
		&::before {
			position: absolute;
			top: 12px;
		}
		&::after {
			position: absolute;
			top: 24px;
		}
		
	}
	&:hover {
		span {
			box-shadow: 0px 0px 4px 1px #d5a172;
			&::after,
			&::before {
				box-shadow: 0px 0px 4px 1px #d5a172;
			}
		}
	}
}
