.main-footer {
	background-color: #251f1f;
}
.footer-form {
	width: 800px;
	margin: 0 auto;
	padding: 100px 0;
}
.footer-form__title {
	text-align: center;
	font-size: 24px;
	margin-bottom: 30px;
	color: #fff;
	font-family: "playfair-regular";
}
.footer-form__row {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}
.footer-form__row .footer-form-item {
	width: 48%;
	margin-bottom: 0;
}
.footer-form-item {
	margin-bottom: 30px;
	position: relative;
}
.footer-input-line {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	content: "";
	display: block;
	height: 2px;
	width: 100%;
	background-color: #fff;
	transition: 0.2s linear;
}
.footer-form__input {
	font-weight: 700;
	letter-spacing: 0.5px;
	display: block;
	padding: 12px 10px 10px;
	line-height: 12px;
	font-size: 12px;
	width: 100%;
	font-weight: 700;
	letter-spacing: 0.5px;
	font-family: "muller-ultra-light", Arial, sans-serif;
	border: none;
	color: #fff;
	outline: none;
	border-bottom: 1px solid transparent;
	background-color: transparent;
	
	@media (min-width: 480px) {
		padding: 16px 10px 14px;
		line-height: 10px;
		font-size: 10px;
	}
	&::placeholder {
		color: #fff;
		font-weight: 700;
		letter-spacing: 0.5px;
		text-transform: uppercase;
	}
	&:focus + .footer-input-line {
		background-color: #d5a172;
		box-shadow: 0 1px 10px 0px #d5a172;
	}
}
.footer-form__textarea {
	font-weight: 700;
	letter-spacing: 0.5px;
	display: block;
	width: 100%;
	resize: none;
	padding: 12px 10px;
	font-size: 10px;
	line-height: 16px;
	height: 64px;
	font-family: "muller-ultra-light", Arial, sans-serif;
	border: none;
	color: #fff;
	outline: none;
	border-bottom: 1px solid transparent;
	background-color: transparent;
	
	@media (min-width: 480px) {
		padding: 15px 10px;
		font-size: 12px;
		line-height: 18px;
		height: 84px;
	}
	&::placeholder {
		color: #fff;
		font-weight: 700;
		letter-spacing: 0.5px;
		text-transform: uppercase;
	}
	&:focus + .footer-input-line {
		background-color: #d5a172;
		box-shadow: 0 1px 10px 0px #d5a172;
	}
}
.footer-form__btn {
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	padding: 16px 55px 14px;
	line-height: 12px;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1px;
	color: #fff;
	text-transform: uppercase;
	margin: 0;
	cursor: pointer;
	text-align: center;
	border: none;
	background-color: transparent;
	box-shadow: 0 1px 10px 0px #d5a172;
	transition: all 0.25s linear;
	&:hover,
	&:focus {
		box-shadow: 0 1px 10px 0px #d5a172;
		color: #d5a172;
	}
}
.footer-form__label {
	margin-left: 40px;
	display: flex;
	align-self: center;
	cursor: pointer;
	input {
		flex-shrink: 0;
		margin-right: 15px;
		margin-top: 2px;
	}
}
.footer-form__text {
	color: #fff;
	font-size: 14px;
	line-height: 1.3;
}
.footer-nav {
	padding: 30px 0;
	background-color: #251f1f;
	border-top: 1px solid #d5a172;
}
.footer-nav__wrapper {
	display: flex;
}
.footer-nav__list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-right: 30px;
}
.footer-nav__item {
	&:not(:last-child) {
		margin-right: 50px;
	}
}
.footer-nav__link {
	display: block;
	padding: 10px 0;
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
	font-family: "playfair-regular";
	transition: 0.2s linear;
	&:hover {
		color: #d5a172;
	}
}

.footer-contacts {
	border-top: 1px solid #fff;
	padding-top: 40px;
	margin-bottom: 60px;
	text-align: center;
	
	@media (min-width: 480px) {
		text-align: left;
		padding-top: 80px;
		margin-bottom: 100px;
	}
	&__logo {
		display: block;
		width: 140px;
		margin: 0 auto 60px;
		
		@media (min-width: 480px) {
			margin: 0 0 60px;
		}
		svg {
			display: block;
			width: 100%;
			height: auto;
			fill: #fff;
			transition: 0.2s linear;
		}
	}
	&__item {
		margin-bottom: 20px;
	}
	&__link {
		color: #fff;
		transition: 0.2s linear;
		&:hover {
			color: #d5a172;
			svg {
				fill: #d5a172;
			}
		}
	}
	&__phone {
	}
	&__email {
	}
	&__address {
		color: #fff;
		line-height: 24px;
	}
}

.footer-copyright {
	background-color: #251f1f;
	color: #fff;
}
.footer-copyright__wrapper {
	display: flex;
	flex-direction: column;
	padding: 0 0 50px;
	
	@media (min-width: 480px) {
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-between;
	}
}
.footer-copyright__left {
	order: 2;
	
	@media (min-width: 480px) {
		order: 1;
	}
	color: #fff;
	span {
		&:first-child {
			margin-right: 30px;
		}
	}
}

.footer-social {
	order: 1;
	margin-bottom: 40px;
	text-align: center;
	
	@media (min-width: 480px) {
		order: 2;
		margin-bottom: 0;
		text-align: left;
	}
	&__item {
		&:not(:first-child) {
			margin-top: 20px;
			
			@media (min-width: 480px) {
				margin-top: 10px;
			}
		}
		a {
			color: #fff;
			transition: 0.2s linear;
			&:hover {
				color: #d5a172;
			}
		}
	}
}