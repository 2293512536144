.advantages {
	margin: 100px 0;
	color: #fff;
	&__wrap {
		display: flex;
	}
	&__col {
		width: 50%;
		&:first-child {
			padding-right: 50px;
		}
		&:last-child {
			padding-left: 50px;
		}
	}
	&__title {
		font-size: 22px;
    font-family: "playfair-regular";
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    color: #fff;
    text-shadow: 1px 1px 0px #251f1f;
		margin-bottom: 20px;
	}
	&__subtitle {
		margin-top: 30px;
		width: 500px;
		line-height: 24px;
	}
	&__image {
		display: block;
		width: 100%;
		height: auto;
		&--height {
			height: 100%;
			object-fit: cover;
		}
	}
}

.advantages-list {
	margin-top: 30px;
	margin-bottom: 50px;
	padding-left: 15px;
	color: #fff;
	
	@media (min-width: 480px) {
		padding-left: 30px;
	}
	&__item {
		position: relative;
		margin-bottom: 30px;
		padding-left: 60px;
		padding-right: 15px;
		
		@media (min-width: 480px) {
			padding-left: 80px;
			padding-right: 100px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__title {
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 12px;
	}
	&__text {
		font-size: 14px;
		line-height: 20px;
	}
	
}