.blog {
	margin-bottom: 100px;
}
.blog__wrapper {
	display: flex;
	flex-wrap: wrap;
}
.blog-item {
	display: block;
	width: 100%;
	margin: 0 0 30px 0;
	
	@media (min-width: 480px) {
		width: 30.33%;
		margin: 0 1.5% 30px 1.5%;
	}
	&:hover {
		.blog-item__info::after {
			display: block;
		}
	}
}
.blog-item__info {
	position: relative;
	background-color: #322b2b;
	padding: 20px 20px 30px;
	&::after {
		position: absolute;
		content: "";
		display: none;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: #d5a172;
		box-shadow: 0 1px 10px 0px #d5a172;
	}
}
.blog-item__title {
	color: #fff;
	max-width: 80%;
	font-size: 18px;
	font-family: "playfair-regular";
	line-height: 24px;
}
.blog-item__date {
	font-size: 10px;
	text-transform: uppercase;
	color: #fff;
	text-shadow: 1px 1px 0px #251f1f;
	margin-bottom: 20px;
	letter-spacing: 3px;
}
.blog-item__img {
	width: 100%;
	img {
		display: block;
		width: 100%;
		height: auto;
		object-fit: cover;
		background-color: gray;
	}
}
.blog__more {
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	padding: 16px 55px 14px;
	line-height: 12px;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1px;
	color: #fff;
	text-transform: uppercase;
	margin: 30px auto 0;
	width: 240px;
	cursor: pointer;
	text-align: center;
	border: none;
	background-color: transparent;
	box-shadow: 0 1px 10px 0px #d5a172;
	transition: all 0.25s linear;
	&:hover,
	&:focus {
		box-shadow: 0 1px 10px 0px #d5a172;
		color: #d5a172;
	}
}