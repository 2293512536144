.main-advantages {
	margin-bottom: 50px;
	
	@media (min-width: 480px) {
		margin-bottom: 100px;
	}
}
.main-advantages-head__title {
	color: #fff;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 30px;
	font-family: "playfair-regular";
	text-align: center;
	
	@media (min-width: 480px) {
		font-size: 22px;
		line-height: 1.15;
	}
}
.main-advantages__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	@media (min-width: 480px) {
		flex-direction: row;
	}
}
.main-advantages__item {
	position: relative;
	width: 100%;
	padding: 20px 20px;
	margin-bottom: 40px;
	border-bottom: 1px solid #d5a172;
	
	@media (min-width: 480px) {
		width: 33%;
		padding: 20px 30px;
		border-bottom: none;
		margin-bottom: 0;
		border-left: 1px solid #d5a172;
	}
	&:last-child {
		@media (min-width: 480px) {
			border-right: 1px solid #d5a172;
		}
	}
}
.main-advantages__number {
	position: absolute;
	font-size: 100px;
	line-height: 90px;
	font-family: "playfair-regular";
	color: #fff;
	opacity: 0.2;
	top: -20px;
	left: 15%;
}
.main-advantages__title {
	color: #fff;
	text-transform: uppercase;
	margin-bottom: 20px;
	font-size: 18px;
	font-family: "playfair-regular";
}
.main-advantages__text {
	color: #fff;
	font-size: 16px;
	line-height: 24px;
	em {
		font-size: 16px;
		font-family: "playfair-italic";
		font-style: italic;
	}
}