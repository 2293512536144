.instagram {
	margin: 100px 0 50px;
	&__title {
		color: #fff;
		font-size: 22px;
		margin-bottom: 30px;
		font-family: "playfair-regular";
	}
	&__list {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		margin-top: 60px;
		
		@media (min-width: 480px) {
			flex-direction: row;
		}
	}
}

.instagram-item {
	position: relative;
	width: 260px;
	margin-right: 0;
	margin-bottom: 70px;
	color: #fff;
	
	@media (min-width: 480px) {
		width: 350px;
		margin-right: 75px;
		margin-bottom: 50px;
	}
	&:last-child {
		margin-right: 0;
	}
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: -30px;
		right: -30px;
		width: 100%;
		height: 260px;
		border: 1px solid rgba(#d5a172, 1);
		
		@media (min-width: 480px) {
			top: -40px;
			right: -40px;
			width: 100%;
			height: 350px;
		}
	}
	&:nth-child(2) {
		@media (min-width: 480px) {
			margin-top: 80px;
		}
	}
	&:nth-child(3) {
		@media (min-width: 480px) {
			margin-top: 160px;
		}
	}
	&__image {
		position: relative;
		display: block;
		width: 100%;
		height: 260px;
		object-fit: cover;
		
		@media (min-width: 480px) {
			height: 350px;
		}
	}
	&__content {
		margin-top: 20px;
		font-size: 14px;
		line-height: 20px;
		
		@media (min-width: 480px) {
			font-size: 16px;
			line-height: 24px;
		}
	}
	&__author {
		font-size: 12px;
		margin-top: 10px;
		color: #d5a172;
		
		@media (min-width: 480px) {
			font-size: 14px;
			margin-top: 15px;
		}
	}
}
