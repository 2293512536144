.preloader {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: #333;
	&.loaded {
		display: none;
	}
}

.preloader__content {
	width: 190px;
	height: 120px;
}

.preloader__count {
	display: block;
	text-align: right;
	font-size: 120px;
	line-height: 120px;
	color: #fff;
}

.for-preload-test {
	height: 0;
	width: 0;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}