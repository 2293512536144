.services-desc {
	position: relative;
	height: 100vh;
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: rgba(#000, 0.7);
		}
	}
	&__image {
		width: 100%;
		height: 100%;
	}
	&__wrap {
		position: relative;
		z-index: 2;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: #fff;
		padding-bottom: 60px;
	}
	&__title {
    margin-bottom: 0;
    font-size: 22px;
    font-family: "playfair-regular";
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    color: #fff;
    text-shadow: 1px 1px 0px #251f1f;
	}
	&__subtitle {
		margin-top: 30px;
		width: 500px;
		line-height: 24px;
	}
}
.services-desc-head {
	color: #fff;
	&__title {
		font-size: 22px;
		margin-bottom: 30px;
		font-family: "playfair-regular";
	}
	&__subtitle {
		font-size: 16px;
		margin-bottom: 20px;
		line-height: 20px;
	}
	&__text {
		font-size: 20px;
		margin-bottom: 30px;
		line-height: 24px;
	}
}

.services-desc-content {
	margin: 100px auto 0;
	width: 800px;
}

.services-desc-select {
	&__title {
		position: relative;
//		padding-top: 20px;
		font-size: 16px;
		color: #fff;
		&::before {
			content: "";
			display: block;
			width: 60px;
			height: 1px;
			margin-bottom: 30px;
			background-color: #d5a172;
		}
	}
	&__list {
		margin-top: 10px;
		display: flex;
	}
	&__col {
		margin-right: 50px;
	}
	&__link {
		margin-top: 18px;
		a {
			color: #fff;
			padding-bottom: 3px;
			border-bottom: 1px dotted #d5a172;
			transition: 0.2s linear;
			&:hover {
				color: #d5a172;
			}
		}
	}
}

.contact-us {
	margin: 100px 0;
	&__wrap {
		margin: 30px auto;
		display: flex;
		justify-content: center;
		.main-links__item:first-child {
			margin-right: 0;
		}
	}
}