.about-head {
	position: relative;
	height: 100vh;
//	&__bg {
//		position: absolute;
//		top: 0;
//		left: 0;
//		z-index: 1;
//		width: 100%;
//		height: 100%;
//		&::before {
//			content: "";
//			display: block;
//			position: absolute;
//			top: 0;
//			left: 0;
//			z-index: 1;
//			width: 100%;
//			height: 100%;
//			background-color: rgba(#000, 0.7);
//		}
//	}
	&__right {
		flex-shrink: 0;
		width: 50%;
		padding-left: 50px;
		height: 50vh;
	}
	&__image {
		width: 100%;
		height: 100%;
		background-size: cover;
	}
	&__content {
		flex-shrink: 0;
		width: 50%;
		padding-right: 50px;
	}
	&__wrap {
		position: relative;
		z-index: 2;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
	&__title {
    margin-bottom: 0;
    font-size: 22px;
    font-family: "playfair-regular";
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    color: #fff;
    text-shadow: 1px 1px 0px #251f1f;
	}
	&__subtitle {
		margin-top: 30px;
		width: 500px;
	}
}

.about {
	color: #fff;
	margin-top: 120px;
	
	@media (min-width: 480px) {
		margin-top: 150px;
	}
	&__row {
		margin: 100px 0;
	}
	&__title {
		font-size: 20px;
    font-family: "playfair-regular";
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    color: #fff;
    text-shadow: 1px 1px 0px #251f1f;
		margin-bottom: 20px;
	}
	&__subtitle {
		margin-top: 30px;
		max-width: 500px;
		width: 100%;
		line-height: 24px;
		
		@media (min-width: 480px) {
			width: 500px;
		}
	}
	&__wrap {
		display: flex;
		flex-direction: column;
		
		@media (min-width: 480px) {
			flex-direction: row;
		}
	}
	&__col {
		width: 100%;
		
		@media (min-width: 480px) {
			width: 50%;
		}
		&:first-child {
			@media (min-width: 480px) {
				padding-right: 50px;
			}
		}
		&:last-child {
			@media (min-width: 480px) {
				padding-left: 50px;
			}
		}
	}
	&__image {
		display: none;
		
		@media (min-width: 480px) {
			display: block;
			width: 100%;
			height: auto;
		}
		&--height {
			height: 100%;
			object-fit: cover;
		}
	}
	&__text {
		p {
			font-size: 14px;
    	line-height: 20px;
			
			@media (min-width: 480px) {
				font-size: 16px;
    		line-height: 24px;
			}
		}
	}
	&__btn {
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		padding: 16px 55px 14px;
		line-height: 12px;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1px;
		color: #fff;
		text-transform: uppercase;
		margin: 30px 0 0;
		width: 240px;
		cursor: pointer;
		text-align: center;
		border: none;
		background-color: transparent;
		box-shadow: 0 1px 10px 0px #d5a172;
		transition: all 0.25s linear;
		&:hover,
		&:focus {
			box-shadow: 0 1px 10px 0px #d5a172;
			color: #d5a172;
		}
	}
}

.about-team {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	
	@media (min-width: 480px) {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
	}
	&__item {
		max-width: 260px;
		width: 100%;
		margin-bottom: 40px;
		box-shadow: 0 1px 10px 0px #d5a172;
		
		@media (min-width: 480px) {
			max-width: 350px;
			width: 350px;
			margin-bottom: 50px;
		}
	}
	&__image {
		width: 100%;
		height: 300px;
		
		@media (min-width: 480px) {
			height: 400px;
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__name {
		margin-top: 15px;
		font-size: 18px;
		padding: 0 10px;
    font-family: "playfair-regular";
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
		
		@media (min-width: 480px) {
			margin-top: 20px;
			font-size: 20px;
			padding: 0 15px;
			line-height: 20px;
		}
	}
	&__position {
		font-size: 14px;
		margin-top: 10px;
		padding: 0 10px 10px;
		
		@media (min-width: 480px) {
			margin-top: 15px;
			padding: 0 15px 15px;
			font-size: 16px;
		}
	}
}

.about-resume {
	margin-top: 100px;
	padding-bottom: 30px;
	margin-bottom: 40px;
	
	@media (min-width: 480px) {
		margin-bottom: 70px;
	}
}

.about-resume-head {
	margin-bottom: 50px;
	text-align: center;
	&__title {
		position: relative;
		color: #fff;
		font-size: 18px;
		padding-bottom: 15px;
		margin-bottom: 20px;
		font-family: "playfair-regular";
		
		@media (min-width: 480px) {
			font-size: 22px;
			padding-bottom: 20px;
			margin-bottom: 25px;
		}
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: calc(50% - 50px);
			display: block;
			width: 100px;
			height: 1px;
			background-color: #d5a172;
		}
	}
	&__text {
		color: #fff;
		max-width: 600px;
		width: 100%;
		margin: 0 auto 20px;
		font-size: 14px;
		line-height: 20px;
		
		@media (min-width: 480px) {
			width: 600px;
			font-size: 16px;
			line-height: 24px;
		}
	}
}


.about-resume-form {
	max-width: 500px;
	width: 100%;
	margin: 0 auto;
	
	@media (min-width: 480px) {
		width: 500px;
	}
	.footer-form__btn {
		margin: 5px auto 0;
		
		@media (min-width: 480px) {
			margin: 15px auto 0;
		}
	}
	.footer-form__input {
		font-size: 10px;
		line-height: 10px;
		
		@media (min-width: 480px) {
			font-size: 14px;
			line-height: 14px;
		}
	}
	.footer-form__textarea {
		font-size: 10px;
		line-height: 16px;
		
		@media (min-width: 480px) {
			font-size: 14px;
			line-height: 20px;
		}
	}
}
.label-file {
	display: block;
	margin-top: 30px;
	margin-bottom: 40px;
	
	@media (min-width: 480px) {
		margin-top: 50px;
		margin-bottom: 60px;
	}
	input {
		display: none;
	}
	.input-file {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 40px;
		color: #fff;
		border: 1px dotted #fff;
		cursor: pointer;
		transition: 0.2s linear;
		&:hover {
			border-color: #d5a172;
		}
		span {
			display: block;
		}
		svg {
			display: block;
			margin-bottom: 4px;
			margin-right: 10px;
			fill: #fff;
		}
	}
}

.about-list {
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
	&__item {
		position: relative;
		width: 100%;
		margin-bottom: 50px;
		margin-right: 0;
		padding-left: 60px;
		
		@media (min-width: 480px) {
			width: 250px;
			margin-bottom: 50px;
			margin-right: 50px;
			padding-left: 60px;
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	&__title {
		margin-bottom: 15px;
		line-height: 24px;
	}
	&__text {
		font-size: 14px;
		line-height: 20px;
	}
}
.decorative {
	position: absolute;
	left: 5px;
	top: 5px;
}
.decorative--squarecircle {
	width: 32px;
	height: 32px;
	display: block;
	.box,
	.circle {
		position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin-left: -16px;
    margin-top: -16px;
	}
	.box {
		border: 1px solid #fff;
    border-radius: 3px;
		transform: translate(-5px,5px);
	}
	.circle {
		border: 1px solid #d5a172;
    background-color: rgba(#d5a172, 0.2);
    border-radius: 50%;
    z-index: 1;
		transform: translate(5px,-5px);
	}
}
.about-team-head {
	color: #fff;
	margin-top: 50px;
	
	@media (min-width: 480px) {
		margin-top: 120px;
	}
	.about__row {
		margin: 50px 0;
		
		@media (min-width: 480px) {
			margin: 100px 0;
		}
	}
	.about__title {
		text-align: center;
		
		@media (min-width: 480px) {
			text-align: left;
		}
	}
}