.main-screen {
	position: relative;
	height: 108vh;
	background: #251f1f;
}
.main-slider {
	height: 100%;
}
.main-slider-item {
	position: relative;
	height: 100%;
	&::before {
		content: "";
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
	}
}
.main-screen__wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.main-slider-item__bg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.main-screen__logo {
	display: none;
	@media (min-width: 480px) {
		display: block;
		width: 800px;
		margin-bottom: 40px;
	}
	svg {
		fill: #fff;
	}
}
.main-slider-item__letter {
	display: none;
	
	@media (min-width: 480px) {
		display: block;
		position: absolute;
		z-index: 2;
		top: 5%;
		left: 40%;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	svg {
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		display: block;
		width: 900px;
		path {
			fill: rgba(255, 255, 255, 0.1);
		}
	}
}
.main-slider-item__wrap {
	position: relative;
	width: 300px;
	height: 100%;
	margin: 0 auto;
	padding-bottom: 8vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 2;
	
	@media (min-width: 480px) {
		width: 1200px;
		height: 100%;
	}
}
.main-screen__title {
	width: 100%;
	padding-top: 100px;
	margin-bottom: 20px;
	font-size: 16px;
	font-family: "playfair-regular";
	line-height: 28px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: normal;
	color: #fff;
	text-shadow: 1px 1px 0px #251f1f;
	
	@media (min-width: 480px) {
		width: 675px;
		padding-top: 140px;
		font-size: 22px;
		line-height: 40px;
		margin-bottom: 40px;
	}
}
.main-screen__btn {
	margin-right: auto;
	padding: 14px 35px 12px;
	line-height: 10px;
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 1px;
	color: #fff;
	text-transform: uppercase;
	box-shadow: 0 1px 10px 0px #d5a172;
	transition: all 0.25s linear;
	
	@media (min-width: 480px) {
		padding: 16px 55px 14px;
		line-height: 12px;
		font-size: 12px;
	}
	&:hover,
	&:focus {
		box-shadow: 0 1px 10px 0px #d5a172;
		color: #d5a172;
	}
}
.main-links {
	margin-top: 40px;
	
	@media (min-width: 480px) {
		margin-top: 80px;
		display: flex;
	}
}
.main-links__item {
	display: flex;
	flex-direction: column;
	position: relative;
	padding-left: 50px;
	flex-wrap: wrap;
	margin-right: 50px;
	margin-bottom: 20px;
	&:first-child {
		margin-right: 50px;
	}
	&:hover {
		.main-links__arrow .rect {
			border: 1px solid rgb(190, 145, 106);
//			border-left: none;
		}
		.main-links__arrow .arrow {
			transform: translateX(5px);
		}
	}
}
.main-links__title {
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}
.main-links__desc {
	color: #fff;
	font-size: 16px;
	line-height: 18px;
	margin-top: 4px;
	opacity: 0.7;
}
.main-links__arrow {
	position: absolute;
	display: block;
	width: 32px;
	height: 34px;
	left: 0;
	top: 50%;
	margin-top: -17px;
	svg {
		display: block;
    position: absolute;
	}
	.arrow {
    position: absolute;
    left: 0;
    top: 50%;
    width: 13px;
    height: 12px;
    margin-top: -6px;
    display: block;
    transition: transform .25s cubic-bezier(.215,.61,.355,1);
    transition: transform .25s cubic-bezier(.215,.61,.355,1);
		path {
			fill: #fff;
		}
	}
	.rect {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
    top: 0;
		box-shadow: 0 1px 10px 0px #d5a172;
		border: 1px solid transparent;
//		border-left: none;
		transition: all .25s cubic-bezier(.215,.61,.355,1);
	}
}

.main-screen__scroll {
	display: none;
	
	@media (min-width: 480px) {
		display: block;
		position: absolute;
		bottom: 11vh;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
	}
}

.main-slider-pagination.swiper-pagination-bullets {
	bottom: 11vh;
	left: 50%;
	position: absolute;
	z-index: 5;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	
	@media (min-width: 480px) {
		bottom: 15vh;
		left: calc((100vw - 1200px) / 2);
		position: absolute;
		z-index: 5;
		transform: translateX(0);
		display: block;
	}
	.swiper-pagination-bullet {
		position: relative;
		width: 20px;
		height: 20px;
		margin: 0 5px;
		border-radius: 0;
		opacity: 1;
		background-color: transparent;
		border: 1px solid transparent;
		box-shadow: 1px 1px 10px 0px #d5a172;
		transition: all 0.25s linear;
		&-active,
		&:hover {
			border: 1px solid #d5a172;
		}
	}
}

.main-slider-count {
	display: none;
	
	@media (min-width: 480px) {
		display: block;
		position: absolute;
		bottom: 20vh;
		z-index: 2;
		width: 100%;
	}
	&__wrap {
		width: 1170px;
		margin: 0 auto;
		display: flex;
		color: #fff;
	}
	&__current {
		font-size: 24px;
		font-family: "playfair-regular";
	}
	&__summ {
		font-size: 14px;
		display: flex;
		&::before {
			display: block;
			content: "/";
			margin: 0 2px 0 5px;
		}
	}
	.summ {
		
	}
}