.page-line {
	position: fixed;
	top: 0;
	height: 100%;
	width: 1px;
	background-color: #fff;
	opacity: .05;
	z-index: 50;
	left: 25%;
	
	@media (min-width: 480px) {
		left: 20%;
	}
	&:nth-child(2) {
		left: 50%;
		
		@media (min-width: 480px) {
    	left: 40%;
		}
	}
	&:nth-child(3) {
		left: 75%;
		
		@media (min-width: 480px) {
    	left: 60%;
		}
	}
	&:nth-child(4) {
		display: none;
		
		@media (min-width: 480px) {
			display: block;
    	left: 80%;
		}
	}
}